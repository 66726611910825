import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import s from './TabBar.module.scss';
import {useGetModulesQuery} from "../../redux/apis/modulesApi";
import logo from "assets/icons/logo.svg";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {ModalGeneral} from "../Modals/ModalGeneral";
import logOutIcon from "assets/modalsImg/logOut.webp";
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg";
import { ReactComponent as WebsitePages } from "assets/icons/websitePages.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Language } from "assets/icons/language.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

export const TabBar = () => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData')
        ? JSON.parse(localStorage.getItem('userData')!)
        : null;
    const modules = useGetModulesQuery({});
    const navigate = useNavigate();
    const [showModal, setShowModal]= useState<boolean>(false);
    const [hide, setHide] = useState<boolean>(false);
    const location = useLocation();

    if(!token) return <h1>Loading...</h1>;

    const modules_view = modules?.data?.map((el: any) => {
        return (
            <Link
                key={el.id}
                to={`module/${el.type}`}
                className={`${s.menu_item} ${`/module/${el.type}` === location.pathname ? s.active : ""}`}
            >
                <Dashboard className={s.iconItem} />
                <span>{el.title}</span>
            </Link>
        )
    });

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        navigate("/auth/");
        setShowModal(false);
    }

    return (
        <section className={s.left_menu}>
            <img src={logo} alt={s.logo} className={s.logo} />

            <div className={s.overflow}>
                <div className={s.menu}>
                    <Link
                        to="/"
                        className={`${s.menu_item} ${"/" === location.pathname && s.active}`}
                    >
                        <Dashboard className={s.iconItem}/>
                        <span>Главная страница</span>
                    </Link>

                    <Link
                        to="/pages"
                        className={`${s.menu_item} ${"/pages" === location.pathname ? s.active : ""}`}
                    >
                        <WebsitePages className={s.iconItem}/>
                        <span>Страницы</span>
                    </Link>

                    <div className={`${s.menu_main} ${hide ? s.hide : ''}`}>
                        <h2>
                            Основные модули
                            <Arrow className={s.arrow} onClick={() => setHide(!hide)}/>
                        </h2>
                        {modules_view}
                    </div>

                    <div className={s.menu_general}>
                        <h2>Общие</h2>
                        {userData?.id > 1 ? null : (
                            <Link
                                to="/users"
                                className={`${s.menu_item} ${"/users" === location.pathname ? s.active : ""}`}
                            >
                                <Users className={s.iconItem}/>
                                <span>Пользователи</span>
                            </Link>

                        )}
                        <Link
                            to="/language"
                            className={`${s.menu_item} ${"/language" === location.pathname ? s.active : ""}`}
                        >
                            <Language className={s.iconItem}/>
                            <span>Языки</span>
                        </Link>

                    </div>
                </div>

                <div className={s.bottom_menu}>
                    <SecondaryButton
                        type="button"
                        text={"Выход"}
                        onClick={() => setShowModal(true)}
                        icon={<Logout/>}
                    />
                </div>
            </div>

            <ModalGeneral
                showModal={showModal}
                setShowModal={setShowModal}
                image={logOutIcon}
                buttonRight={logOut}
                title={"Вы уверены, что хотите выйти?"}
                textLeftButton={"Остаться"}
                textRightButton={"Выйти"}
            />
        </section>
    )
}
import React, { useState } from 'react';
import s from './DropDownSecond.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {NavigateFunction} from "react-router-dom";
import ReactQuill from "react-quill";

type DropDownSecond = {
    langs?: any;
    label?: string;
    value?: any;
    refDrop?: any;
    onChange?: any;
    el?: any;
    image?: boolean;
    values?: any;
    video?: boolean;
    videoRef?: any;
    navigate: NavigateFunction;
    defaultImage?: any;
    setDefaultImage?: React.Dispatch<any>;
    activeImages?: any;
};

export const DropDownSecond = ({
                                   langs,
                                   label,
                                   value,
                                   refDrop,
                                   onChange,
                                   el,
                                   image,
                                   values,
                                   video,
                                   videoRef,
                                   navigate,
                                   defaultImage,
                                   setDefaultImage,
                                   activeImages
                               }: DropDownSecond) => {
    const [hideStates, setHideStates] = useState<{ [key: string]: boolean }>({});

    const toggleHide = (key: string) => {
        setHideStates((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleImageChange = (key: string, ev: any) => {
        onChange(ev.target, key, values.file?.find((el: any) => el.langkey === key)?.id);
    };

    return (
        <div className={s.dropdown}>
            <label>Языки</label>
            {/*<div className={s.input_container}>*/}
            {/*    <div className={s.input_content}>*/}
            {/*        {langs?.map((el: any) => (*/}
            {/*            <div className={s.value}>*/}
            {/*                {el.key}*/}
            {/*                <Cross className={s.icon_delete}  onClick={() => navigate("/language", { state: { deleteLang: el.id, nameLang: el.key } })} />*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}

            {/*    <button*/}
            {/*        type={"button"}*/}
            {/*        className={s.button}*/}
            {/*        onClick={() => navigate("/language", { state: { addLang: true } })}*/}
            {/*    >*/}
            {/*        Добавить*/}
            {/*        <Plus className={s.icon_add}/>*/}
            {/*    </button>*/}
            {/*</div>*/}

            {langs?.map((ln: any) => (
                <div className={`${s.content} ${!hideStates[ln.key] && s.hide}`} key={ln.key}>
                    <div className={s.content_head}>
                        <p>
                            {label} <span>{ln.key}</span>
                        </p>

                        <ArrowTools onClick={() => toggleHide(ln.key)} className={s.arrow}/>
                    </div>

                    {image ? (
                        <div
                            className={s.image_wrapper}
                            ref={(el) => (refDrop.current[ln.key] = el)}
                            style={{
                                backgroundImage: `url(${values?.file?.find(
                                    (el: any) => el.langkey === ln.key
                                )?.link})`,
                            }}
                        >
                            <input
                                className={s.change_input}
                                name="image"
                                type="file"
                                accept="image/*"
                                onChange={(ev: any) => handleImageChange(ln.key, ev)}
                            />
                        </div>
                    ) : video ? (
                        <div
                            className={s.image_wrapper}
                            ref={(el) => refDrop.current[ln.key] = el}
                            style={{backgroundImage: `url(${values?.file?.find((el: any) => el.langkey === ln.key)?.link})`}}
                        >
                            <input
                                className={s.change_input}
                                name='video'
                                type="file"
                                accept='video/*'
                                onChange={ev => handleImageChange(ln.key, ev)}
                            />
                            <div className={s.video_wrapper}>
                                <video
                                    playsInline
                                    ref={(el) => videoRef.current[ln.key] = el}
                                    src={values.file?.find((el: any) => el.langkey === ln.key)?.link}
                                    controls={!!values.file?.path}
                                    style={{width: "100%", height: "100%"}}
                                />
                            </div>
                        </div>
                    ) : (
                        <ReactQuill
                            data-refval={ln.id}
                            value={refDrop.current[ln.key]?.value ?? value[ln.id]?.value}
                            ref={(ref) => (refDrop.current[ln.key] = ref)}
                            onChange={(content) => onChange({ target: { value: content } }, "quill")}
                            modules={{
                                toolbar: false
                            }}
                            readOnly={false}
                        />
                    )}
                    {defaultImage && <div className={s.default_wrapper}>
                        <input
                            type="checkbox"
                            checked={defaultImage?.langkey === ln.key}
                            onChange={() => setDefaultImage && setDefaultImage({
                                   langkey: ln.key,
                                   id: values.file?.find((el: any) => el.langkey === ln.key)?.id
                               })}
                            disabled={!activeImages[ln.key]}
                        />
                        <span>По умолчанию</span>
                    </div>}
                </div>
            ))}
        </div>
    );
};

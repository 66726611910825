import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {pagesApi, useAddPageMutation, useUpdatePageMutation} from "../../../../redux/apis/pagesApi";
import { Form, Formik} from "formik";
import s from "./PagesModal.module.scss";
import * as yup from 'yup';
import {sectionApi} from "../../../../redux/apis/sectionApi";
import {Back} from "components/Back/Back";
import {Input} from "components/Input/Input";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import {DropDown} from "components/DropDown/DropDown";
import {useStatusContext} from "components/StatusProvider";

type PagesModal = {
    close: any;
    el: any;
    pageState: any;
}

export const PagesModal = ({ close, el, pageState }: PagesModal) => {
    const langs = useSelector((store: any) => store.main.langs)
    const dispatch = useDispatch();
    const initialValues: any = {
        id: el? el.id : '',
        name: el? el.name : '',
        content: el? el.content : '',
        marker: el?.marker
    };

    langs.forEach((lang: any) => {
        initialValues[`title-${lang.key}`] = el? el.params?.head?.title[lang.key] : ''
        initialValues[`description-${lang.key}`] = el? el.params?.head?.description[lang.key] : ''
        initialValues[`keywords-${lang.key}`] = el? el.params?.head?.keywords[lang.key] : ''
    })

    const validation_data: any = {
        name: yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа'),
        marker: yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа')
    }
    langs.forEach((el: any) => {
        validation_data[`title-${el.key}`] = yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа')
        validation_data[`description-${el.key}`] = yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа')
        validation_data[`keywords-${el.key}`] = yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа')
    })

    const validationSchema = yup.object().shape(validation_data);

    const [setPage] = useAddPageMutation();
    const [updatePage] = useUpdatePageMutation();
    const { openStatus } = useStatusContext();

    return (
        <div className={s.pages_modal}>
            <Back close={close} text={"Назад к страницам"} />

            <div className={s.form}>
                <h2>{el? "Редактировать страницу" : "Добавить страницу"}</h2>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, {setSubmitting, resetForm})=> {
                        setSubmitting(false)
                        const head: any = {title: {}, description: {}, keywords: {}}
                        langs.forEach((el: any) => {
                            head.title[el.key] = values[`title-${el.key}`]
                            head.description[el.key] = values[`description-${el.key}`]
                            head.keywords[el.key] = values[`keywords-${el.key}`]
                        })
                        if(el){
                            const data = {id: initialValues.id, name: values.name, head: head, marker: values.marker}
                            const res: any = await updatePage(data);
                            if(res.error?.data.message === 1000) {
                                openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                                console.log(res);
                                close(false);
                            }
                            else if(res.error?.data.message === 30) {
                                openStatus('error', 'Произошла ошибка');
                            }
                            else {
                                openStatus('success', 'Операция успешно выполнена!');
                                console.log(res.data);
                                // @ts-ignore
                                dispatch(pagesApi.util.resetApiState(['Pages']));
                                // @ts-ignore
                                dispatch(sectionApi.util.resetApiState(['Section']));
                                close(false);
                            }
                        }
                        else {
                            const data = {id: initialValues.id, name: values.name, head: head, marker: values.marker}
                            const res: any = await setPage(data);
                            if(res.error?.data.message === 1000){
                                openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                                close(false);
                            }
                            else if(res.error?.data.message === 30){
                                openStatus('error', `Имя ${res.error.data.data.name} уже занято`);
                            }
                            else {
                                openStatus('success', 'Операция успешно выполнена!');
                                // @ts-ignore
                                dispatch(pagesApi.util.resetApiState(['Pages']));
                                // @ts-ignore
                                dispatch(sectionApi.util.resetApiState(['Section']));
                                close(false);
                            }
                        }
                    }}
                >
                    {({errors, touched, setFieldValue, values}) => (
                        <Form className={s.pages_form}>
                            <Input
                                label={"Название страницы"}
                                errors={errors.name}
                                touched={touched.name}
                                name={"name"}
                                onClick={() => setFieldValue('name', '')}
                            />

                            <Input
                                label={"Маркер"}
                                errors={errors.marker}
                                touched={touched.marker}
                                name={"marker"}
                                onClick={() => setFieldValue('marker', '')}
                            />

                            <DropDown
                                langs={langs}
                                errors={errors}
                                touched={touched}
                                kind={"title"}
                                label={"Название"}
                            />

                            <DropDown
                                langs={langs}
                                errors={errors}
                                touched={touched}
                                kind={"description"}
                                label={"Описание"}
                            />

                            <DropDown
                                langs={langs}
                                errors={errors}
                                touched={touched}
                                kind={"keywords"}
                                label={"Ключевые слова"}
                            />

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={"Подтвердить"}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={"Отменить"}
                                    type={"button"}
                                    white
                                    onClick={() => close(false)}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
import {useSelector} from "react-redux";
import s from './ModuleFileItem.module.scss';

type ModuleFileItemProps = {
    data: any;
    setModal: any;
}

export const ModuleFileItem = ({ data, setModal }: ModuleFileItemProps) => {
    const ln = useSelector((store: any) => store.main.langs)

    return(
        <div className={s.wrapper}>
            <header>
                <div className={s.left}>
                    <div className={s.header_item}>
                        <span className={s.title}>type:</span>
                        <span className={s.value}>{data.title}</span>
                        <span className={s.title}>id:</span>
                        <span className={s.value}>{data.id}</span>
                    </div>
                </div>
                <div className={s.tools}>
                    <span className={s.edit_icon} onClick={()=>setModal({state: true, values: data.value, type: data.type, post_id: data.id})}>
                        {/*{<SettingsIcon/>}*/}
                    </span>
                </div>
            </header>
            <div className={s.content}>
                {ln?.length && ln?.map((ln: any, index: number) =>

                    data.value?.find((el: any) => el.langkey === ln.key)?.link &&
                    <div className={s.image_item_wrapper} key={index}>
                        <div className={`${s.header} ${data.value?.find((el: any) => el.langkey === ln.key)?.default_image? s.color : ""}`}>
                            <span className={s.language}>{ln.key}</span>
                            {
                                data.value?.find((el: any) => el.langkey === ln.key)?.default_image &&
                                <span>| Default</span>
                            }
                        </div>
                        <div className={s.image_wrapper} style={{
                            backgroundImage: `url(${data.value?.find((el: any) => el.langkey === ln.key)?.link})`
                        }}>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
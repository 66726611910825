import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    blocksApi,
    useAddBlockNewMutation,
    useGetBlocksTypesQuery,
    useUpdateBlockNewMutation
} from "../../../../redux/apis/blocksApi";
import {termsApi, useCheckTermsPseudoMutation, useGetTermsQuery} from "../../../../redux/apis/termsApi";
import {useGetLangsQuery} from "../../../../redux/apis/langApi";
import {mediaApi, useGetMediaQuery} from "../../../../redux/apis/mediaApi";
import {Preloader} from "components/Preloader/Preloader";
import {Toast} from "utils/toast";
import {sectionApi} from "../../../../redux/apis/sectionApi";
import s from './BlocksModal.module.scss';
import {Back} from "components/Back/Back";
import {Select} from "components/Select/Select";
import {DropDownSecond} from "components/DropDownSecond/DropDownSecond";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {useStatusContext} from "components/StatusProvider";
import {useNavigate} from "react-router-dom";

type BlocksModal = {
    close: any;
    section: any;
    values: any;
    lang_data: any;
    type?: any;
    accept?: any;
    tm_name?: any;
    post_body?: any;
}

export const BlocksModal = ({ close, section, values, lang_data, type, accept, tm_name, post_body }: BlocksModal) => {
    let types_view;
    let groups_view;
    let groups_view_media;
    const navigate = useNavigate();

    const ln = useSelector((store: any) => store.main.langs)
    const dispatch = useDispatch();
    const pseudo_ref: any = useRef();
    const marker_ref: any = useRef();
    const new_group_ref: any = useRef();
    const img_wrapper: any = useRef({});
    const group_select: any = useRef();
    const type_select: any = useRef();
    const error_text: any = useRef();
    const video: any = useRef({});
    const terms_values: any = useRef([]);
    const extra_values: any = useRef([]);

    const [formContent, setFormContent] = useState<any>();
    const [content_type, setType] = useState('text');
    const [inputsValues, setInputsValues] = useState({
        ...values?.terms_block,
        'link': {value: values?.link? values.link : ''},
        'pseudo': {value: values?.pseudo? values.pseudo : ''},
        'alt': {value: values?.file?.[0]?.alt? values?.file?.[0]?.alt : ''},
        'marker': {value: values?.marker? values.marker : ''}
    });
    const [defaultImage, setDefaultImage] = useState<any>(undefined)
    const [activeImages, setActiveImages] = useState<any>({})

    const [addBlockNew, {isLoading: isAddBlockLoading}] = useAddBlockNewMutation();
    const [updateBlockNew, {isLoading: isUpdateBlockLoading}] = useUpdateBlockNewMutation();
    const [checkTerms, {isLoading: isCHeckTermsLoading}] = useCheckTermsPseudoMutation();

    const {data, isError, isFetching} = useGetBlocksTypesQuery({});
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});
    const { openStatus } = useStatusContext();
    const [showGroup, setShowGroup] = useState<boolean>(false);
    const [changeInputButton, setChangeInputButton] = useState<boolean>(false);

    useEffect(()=> {
        values?.file?.forEach((file: any) => {
            if(file.default_image) setDefaultImage({langkey: file.langkey, id: undefined})
        })
    },[]);

    useEffect(()=>{
        values?.file?.forEach((el: any) => {
            if(el.link)
                setActiveImages((prev: any) => {return{ ...prev, [el.langkey]: true }})
        })
    },[values.file]);

    if(isFetching || terms.isFetching || lang.isFetching || media.isFetching || isAddBlockLoading || isUpdateBlockLoading || isCHeckTermsLoading) return <Preloader/>
    if(isError) console.log(isError);

    // ### SET CONTENT TYPE

    if(!formContent){
        if(values?.type) changeType(values?.type);
        else if(type) changeType(type);
        else changeType("text");
        return null;
    }


    // ### VIEWS

    const uniqueArray = terms.data.data.filter((obj: any, index: any, self: any) => {
        return self.findIndex((otherObj: any) => obj.group === otherObj.group) === index;
    });

    groups_view = uniqueArray.map((el: any) => {
        return(
            <option key={el.id} value={el.group}>{el.group}</option>
        )
    })

    const uniqueArrayMedia = media.data.data.filter((obj: any, index: number, self: any) => {
        return self.findIndex((otherObj: any) => obj.group === otherObj.group) === index;
    });
    groups_view_media = uniqueArrayMedia.map((el: any) => {
        return(
            <option key={el.id} value={el.group}>{el.group}</option>
        )
    })

    types_view = data.data.map((el: any) => {
        return (
            <option key={el.id} value={el.name}>{el.name}</option>
        )
    })


    // ### FUNCTIONS

    function changeType(value: string){
        switch(value){
            case "text":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('text')
                break;

            case "link":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('link')
                break;

            case "button":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('button')
                break;

            case "image":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('image')
                break;

            case "video":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('video')
                break;

            default: Toast.fire({icon: 'success', title: `Не вірний тип.`});
        }
    }

    async function sendForm(ev: any){
        let err_flag = false;
        error_text.current.innerHTML = "";
        ev.preventDefault();

        if(formContent?.[0]?.type === 'text' || formContent?.[0]?.type === 'link'){
            const pseudo = inputsValues['pseudo'].value;
            const marker = inputsValues['marker'].value;
            const old_group = group_select?.current?.value;
            const new_group = new_group_ref?.current?.value;
            const group = new_group?.length? new_group : old_group;
            const link = extra_values?.current['link']?.value? extra_values?.current['link']?.value : '';

            if(pseudo.length < 2) {
                error_text.current.innerHTML = "Заповніть pseudo";
                err_flag = true;
            }
            if(marker.length < 2) {
                error_text.current.innerHTML = "Заповніть marker";
                err_flag = true;
            }
            lang?.data.data.forEach((el: any) =>{
                if(terms_values.current[el.key].value.length < 1) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            });

            if(err_flag) return;

            if(accept){
                const result_terms : any = {}
                result_terms.text = lang.data.data.map((el: any) => {
                    return {value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id}
                });
                if(tm_name) result_terms.tm_name = tm_name;
                if(post_body) result_terms.post_body = "post_body";
                accept(result_terms);
                close(false);
                return;
            }

            let terms: any[] = [];
            lang?.data.data.map( async (el: any) => {
                terms.push({value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id})
            });

            // Edit block
            if(values.type){
                const response: any = await updateBlockNew({type: content_type, section_id: section.id, link: link, block_id: values.block_id, terms: terms, marker: inputsValues.marker.value});
                if(response.error?.data.message === 1000){
                    openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                    close(false);
                }
                else if (response.data.message === 38) {
                    openStatus('success', 'Операция успешно выполнена!');
                    dispatch(termsApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    close(false);
                }
                return;
            }

            const check_terms = await checkTerms([pseudo]);
            if(check_terms.data){
                openStatus('error', `Псевдоним ${pseudo} уже занят. Пожалуйста, используйте уникальные pseudo для текста.`);
            }

            // Add new block
            const response: any = await addBlockNew({type: content_type, section_id: section.id, link: link, terms: terms, marker: inputsValues.marker.value});
            if(response.error?.data.message === 1000){
                openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                close(false);
            }
            else if(response.data.message === 38){
                openStatus('success', 'Операция успешно выполнена!');
                dispatch(termsApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                close(false);
            }

        }
        else if (formContent[0].type === 'file'){

            let err_flag = false;
            const old_group = group_select?.current?.value;
            const new_group = new_group_ref?.current?.value;
            const group = new_group?.length? new_group : old_group;
            const alt = extra_values.current['alt']?.value;
            const block_id = values?.block_id;

            const form_data = new FormData();
            extra_values?.current.forEach((file: any) => form_data.append(`file-${file.langkey}`, file.file))

            form_data.append("group", group);
            form_data.append("alt", alt);
            form_data.append("media_id", values?.pseudo);
            form_data.append("block_id", block_id);
            form_data.append("file_type", content_type);
            form_data.append("section_id", section?.id);
            form_data.append("marker", inputsValues.marker.value);
            form_data.append("default_image", defaultImage.langkey);
            console.log("form_data", form_data);

            if(content_type === 'image'){
                if(group?.length < 2 || alt.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }
            if(content_type === 'video'){
                if(group.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }

            if(err_flag) return;
            if(accept){
                const result_image: any = {}
                result_image.image = form_data;
                if(tm_name) result_image.tm_name = tm_name;
                if(post_body) result_image.post_body = "post_body";
                accept(result_image);
                close(false);
                return;
            }

            // Edit block
            if(values.type){
                const response: any = await updateBlockNew(form_data);
                if(response.error?.data.message === 1000){
                    openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                    close(false);
                }
                else if(response.data.data.message === 38){
                    openStatus('success', 'Операция успешно выполнена!');
                    dispatch(mediaApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    close(false)
                }
                return;
            }

            // New block
            const response = await addBlockNew(form_data);
            if(response?.data?.message === 38){
                openStatus('success', 'Операция успешно выполнена!');
                dispatch(mediaApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                close(false);
            }

        }

    }

    function previewImage(input: any, lnkey: any, id: number){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e: any) {
                img_wrapper.current[lnkey].style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
                setActiveImages((prev: any) => {return{ ...prev, [lnkey]: true }})
                extra_values.current = extra_values.current.filter((file: any) => file.langkey !== lnkey)
                extra_values.current.push({
                    langkey: lnkey,
                    file: input.files[0]
                })
                setActiveImages((prev: any) => {return{ ...prev, [lnkey]: true }})
                if(!defaultImage) setDefaultImage({langkey: lnkey, id: undefined})
            };
            reader.readAsDataURL(input.files[0]);
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e: any) {
                video.current[lnkey].src = e.target.result;
                video.current[lnkey].setAttribute('controls', true);
                extra_values.current = extra_values.current.filter((file: any) => file.langkey !== lnkey)
                extra_values.current.push({
                    langkey: lnkey,
                    file: input.files[0]
                })
                setActiveImages((prev: any) => {return{ ...prev, [lnkey]: true }})
                if(!defaultImage) setDefaultImage({langkey: lnkey, id: undefined})
            };
            if (input?.files?.[0]) {
                reader?.readAsDataURL(input.files[0]);
            } else {
                console.error('No file selected');
            }
        }
    }

    function changeGroup(ev: any){
        group_select.current.disabled = !!ev.target.value.length;
    }

    function changeInput(el: any, quill?: string){
        setInputsValues((prev: any) => {
            const newState = JSON.parse(JSON.stringify(prev));

            if (quill) {
                if (newState?.['data-refval']) {
                    newState['data-refval'].value = el.value;
                }
            } else {
                if (newState?.[el.getAttribute('data-refval')]) newState[el.getAttribute('data-refval')].value = el.value;
            }

            return newState;
        });
    }

    return (
        <div className={s.pages_modal}>
            <Back close={close} text={"Назад к блокам"} />

            <div className={s.form}>
                <h2>{values?.type ? "Изменить Блок" : "Добавить Блок"}</h2>

                <form className={s.pages_form}>

                    <div className={s.form_row}>
                        <label>Тип</label>
                        <Select
                            values={data?.data}
                            change={changeType}
                            refSelect={type_select}
                            defaultValue={values?.type}
                        />
                    </div>

                    {
                        formContent.map((el: any, index: number) => {
                            if (el.label === "Текст") {
                                return (
                                    <DropDownSecond
                                        langs={lang?.data?.data}
                                        label={"Teкст"}
                                        value={inputsValues}
                                        refDrop={terms_values}
                                        onChange={changeInput}
                                        el={el.label}
                                        navigate={navigate}
                                    />
                                )

                            } else if (el.label === "Ссылка") {
                                return (
                                    <div key={ln.id} className={s.form_row}>
                                        <label>{el.label}</label>
                                        <input
                                            type={el.type}
                                            value={inputsValues['link'].value}
                                            name={el.name}
                                            onChange={(ev) => changeInput(ev.target)}
                                            ref={ref => extra_values.current[el.name] = ref}
                                            data-refval="link"
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className={s.form_row}>
                                        {el.name === 'alt' ?
                                            <>
                                                <label>{el.label}</label>
                                                <input
                                                    type={el.type}
                                                    name={el.name}
                                                    value={inputsValues['alt'].value}
                                                    onChange={(ev) => changeInput(ev.target)}
                                                    ref={ref => extra_values.current[el.name] = ref}
                                                    data-refval={'alt'}
                                                />
                                            </>

                                            :
                                            el.name === 'image' ?
                                                <div className={s.image_items_wrapper}>
                                                    <DropDownSecond
                                                        langs={ln}
                                                        label={"Изображение"}
                                                        value={inputsValues}
                                                        refDrop={img_wrapper}
                                                        onChange={previewImage}
                                                        el={el.label}
                                                        values={values}
                                                        image
                                                        navigate={navigate}
                                                        defaultImage={defaultImage}
                                                        setDefaultImage={setDefaultImage}
                                                        activeImages={activeImages}
                                                    />
                                                </div>
                                                :
                                                <div className={s.image_items_wrapper}>
                                                    <DropDownSecond
                                                        langs={ln}
                                                        label={"Видео"}
                                                        value={inputsValues}
                                                        refDrop={img_wrapper}
                                                        onChange={previewImage}
                                                        el={el.label}
                                                        values={values}
                                                        video
                                                        videoRef={video}
                                                        navigate={navigate}
                                                        defaultImage={defaultImage}
                                                        setDefaultImage={setDefaultImage}
                                                        activeImages={activeImages}
                                                    />
                                                </div>
                                        }
                                    </div>
                                )
                            }
                        })
                    }

                    {content_type === "text" || content_type === "link" ? (
                        <div className={s.form_row}>
                            <label>Псевдоним</label>
                            <input
                                onChange={(ev) => changeInput(ev.target)}
                                data-refval='pseudo'
                                type="text"
                                name="pseudo"
                                value={inputsValues['pseudo'].value}
                                ref={pseudo_ref}
                                disabled={!!values?.type}
                            />
                        </div>
                    ) : null}
                    <div className={s.form_row}>
                        <label>Маркер</label>
                        <input onChange={(ev) => changeInput(ev.target)} data-refval='marker' type="text"
                               name="marker" value={inputsValues['marker'].value} ref={marker_ref}
                               disabled={false}/>
                    </div>
                    <span className="error_text" ref={error_text}></span>
                    <div className={s.wrap_buttons}>
                        <PrimaryButton
                            text={"Подтвердить"}
                            type={"submit"}
                            icon={<Check />}
                            onClick={(ev) => sendForm(ev)}
                        />
                        <SecondaryButton
                            text={"Отменить"}
                            type={"button"}
                            white
                            onClick={() => close(false)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, {useEffect} from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './Users.module.scss';
import {useDeleteUserMutation, useGetUsersQuery} from "../../redux/apis/userApi";
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import {AddUser} from "components/AddUser/AddUser";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import questionMark from 'assets/images/questionMark.webp';
import {Preloader} from "components/Preloader/Preloader";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {Table} from "components/Table/Table";
import {useStatusContext} from "components/StatusProvider";

export const Users = () => {
    const { data: users } = useGetUsersQuery({});
    const [query, setQuery] = useState<string>('');
    const [filteredUsers, setFilteredUsers] = useState<any[]>();
    const [addUser, setAddUser] = useState<boolean>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<number>();
    const [user, setUser] = useState<any>();
    const Swal = require('sweetalert2');
    const navigate = useNavigate();
    const [confirmState, setConfirmState] = useState(false);
    const [role, setRole] = useState<string>();
    const headers = ['ID', 'Роль', 'Электронная почта'];
    const { openStatus } = useStatusContext();

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const [delUser] = useDeleteUserMutation();

    if (users?.isFetching) return <Preloader />

    if (users?.error) {
        if (!confirmState && users?.error?.data.message === 9) {
            openStatus('warning', 'К сожалению, у вас нет необходимых прав');
        }
    }

    async function deleteUser(){
        const result = await delUser(idDelete);

        if (result.data.message === 7 ) {
            openStatus('success', 'Операция успешно выполнена!');
        }
        setShowModal(false);
    }

    const openDeleteModal = (id: number, role: string) => {
        setIdDelete(id);
        setRole(role);
        setShowModal(true);
    }

    const handleSearch = () => {
        const results = users.filter((user: any) =>
            (user.id && String(user.id).toLowerCase().includes(query.toLowerCase())) ||
            (user.info?.name && String(user.info.name).toLowerCase().includes(query.toLowerCase())) ||
            (user.email && String(user.email).toLowerCase().includes(query.toLowerCase()))
        );

        setFilteredUsers(results);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleEdit = (user: any) => {
        setUser(user);
        setAddUser(true);
    }

    const handleAddUser = () => {
        setUser(null);
        setAddUser(true);
    }

    const users_view = filteredUsers?.map((user: any) => {
        return (
            <tr key={user.id}>
                <td><span>{user.id}</span></td>
                <td>{user.info.name}</td>
                <td>{user.email}</td>
                <td className={s.icons}>
                    <span
                        className={s.tools_icon}
                        onClick={(ev) => openDeleteModal(user.id, user.info.name)}
                    >
                        <Delete />
                    </span>
                    <span className={s.tools_icon} onClick={() => handleEdit(user)}>
                        <Edit />
                    </span>
                </td>
            </tr>
        )
    })

    if (addUser) {
        return (
            <section className={s.users}>
                {addUser && <AddUser close={setAddUser} user={user} /> }
            </section>
        )
    }

    const description = (
        <>
            Вы уверены, что хотите удалить пользователя <span>{role}</span> ? <br />
            Он потеряет весь доступ к сайту!
        </>
    );

    return (
        <section className={s.users}>
            <HeaderSection
                title={"Пользователи"}
                buttonText={"Добавить пользователя"}
                icon={<Plus />}
                onClick={handleAddUser}
                description={"Вы можете добавить нового администратора на свой сайт, назначить ему роль и настроить доступ к определенным блокам контента. Это позволит вам контролировать, какие разделы пользователь может редактировать."}
            />

            <Table
                title="Ваши пользователи"
                placeholder="Search (by ID, role or email)"
                query={query}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                handleSearch={handleSearch}
                headers={headers}
                data={users_view}
            />

            <ModalGeneral
                showModal={showModal}
                setShowModal={setShowModal}
                image={questionMark}
                buttonRight={deleteUser}
                title={"Удалить пользователя?"}
                textLeftButton={"Отменить"}
                textRightButton={"Удалить"}
                description={description}
            />
        </section>
    )
}
import React, {ButtonHTMLAttributes} from "react";
import s from "./SecondaryButton.module.scss";

type PropsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    type: "submit" | "reset" | "button" | undefined;
    text: string;
    icon?: any
    white?: boolean,
    className?: string,
    iconRight?: any
}

export const SecondaryButton = ({ disabled, type, text, white, icon, className, iconRight, ...props }: PropsButton) => {
    return (
        <button
            type={type}
            disabled={disabled}
            className={`${s.secondary} ${white ? s.white: ""} ${className}`}
            {...props}
        >
            {icon && icon} {text} {iconRight && iconRight}
        </button>
    )
}
import React, {useEffect, useMemo, useState} from 'react';
import s from "./Pages.module.scss";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {pagesApi, useDeletePageMutation, useGetPagesQuery} from "../../redux/apis/pagesApi";
import {useDispatch, useSelector} from "react-redux";
import {sectionApi, useDeleteSectionMutation, useGetSectionsQuery} from "../../redux/apis/sectionApi";
import {useDeleteBlockMutation, useGetBlocksQuery} from "../../redux/apis/blocksApi";

import edit_icon from 'assets/icons/edit_icon.svg';
import {useGetTermsQuery} from "../../redux/apis/termsApi";
import {useGetLangsQuery} from "../../redux/apis/langApi";
import {useGetMediaQuery} from "../../redux/apis/mediaApi";
import {Preloader} from "components/Preloader/Preloader";
import {key} from "utils/getKey";
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more_vertical.svg";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {MenuMini} from "components/MenuMini/MenuMini";
import deleteIcon2 from 'assets/icons/delete_icon2.svg';
import {PagesModal} from "./componens/PagesModal/PagesModal";
import {SectionModal} from "./componens/SectionModal/SectionModal";
import {BlocksModal} from "./componens/BlocksModal/BlocksModal";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useLocation} from "react-router-dom";
import {useStatusContext} from "components/StatusProvider";

type PropsSectionModal = {
    state?: boolean;
    id?: any;
    el?: any;
}

export const Pages = () => {
    const pages = useGetPagesQuery({});
    const [pageState, setPageState] = useState<any>(false);
    const location = useLocation();
    const locationProps = location.state;

    let sections_view = [];
    const dispatch = useDispatch();
    const [pagesModal, setPagesModal] = useState<PropsSectionModal>({state: false, id: false});
    const [sectionModal, setSectionModal] = useState<PropsSectionModal>({state: false, id: false, el: false});
    const [blocksModal, setBlocksModal] = useState<any>({state: false, section: false, block: false});
    const [langState, setLangState] = useState(false);

    const [deleteSection] = useDeleteSectionMutation();
    const [deleteBlock] = useDeleteBlockMutation();
    const [deletePage] = useDeletePageMutation();

    const sections = useGetSectionsQuery({});
    const blocks = useGetBlocksQuery({});
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});
    const langs = useSelector((store: any) => store.main.langs);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [idMenu, setIdMenu] = useState<number>();
    const [name, setName] = useState<string>();
    const [modalElement, setModalElement] = useState<any>();
    const [showModalPage, setShowModalPage] = useState<boolean>(false);
    const [showModalSection, setShowModalSection] = useState<boolean>(false);
    const [showModalBlock, setShowModalBlock] = useState<boolean>(false);
    const { openStatus } = useStatusContext();

    const [hiddenSections, setHiddenSections] = useState<Set<number>>(new Set());
    const [allow, setAllow] = useState(false);

    const page = pages?.data?.data?.find((el: any) => el.id === pageState.id);

    const section_data = useMemo(() =>
            page?.content?.map((sectionId: any) =>
                sections?.data?.data?.find((section: any) => section.id === sectionId)
            ),
        [page, sections]
    );

    useEffect(() => {
        if (section_data) {
            setHiddenSections(new Set(section_data.map((el: any) => el?.id)));
            setAllow(true);
        }
    }, [section_data]);

    useEffect(() => {
        if (section_data) {
            setHiddenSections(new Set(section_data?.map((el: any) => el?.id)));
            setAllow(true);
        }

    }, [pages]);

    useEffect(() => {
        if (locationProps) {
            setSectionModal({state: true, el: locationProps.section})
        }
    }, [locationProps]);

    const handleMenu = (id: number) => {
        setIdMenu(id);
        setOpenMenu(!openMenu);
    }

    if(sections?.isError || blocks?.isError || terms?.isError)  return null;
    if(sections?.isFetching || blocks?.isFetching || terms?.isFetching || lang?.isFetching || media?.isFetching || pages?.isFetching ) return <Preloader />

    if (sections?.data && blocks?.data && terms?.data && lang?.data && media?.data && pages?.data) {

        if(!pages.data.data.length) return null;
        if(!pageState){
            if(pages.data){
                setPageState(pages.data.data[0]);
            }
            return null;
        }
        if(!langState){
            setLangState(lang.data.data[0].id);
            return null;
        }

        const toggleHide = (id: number) => {
            setHiddenSections((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(id)) {
                    updatedSet.delete(id);
                } else {
                    updatedSet.add(id);
                }
                return updatedSet;
            });
            setAllow(true);
        };

        sections_view = section_data?.map((el: any) => {
            if (!el) return null;
            const blocksInSection =  blocks.data.data?.filter((element: any) => el.content.includes(element.id));

            const section_items = [
                {icon: edit_icon, text: "Редактировать", callback: () => toggleSectionModal(el)},
                {icon: deleteIcon2, text: "Удалить", callback: () => handleDelSection(el)},
            ]

            return (
                <div
                    key={el.id}
                    className={`${s.section}`}
                >
                    <div className={`${s.section_wrap} ${(!hiddenSections.has(el.id) && allow) ? s.hide : ''}`}>
                        <div className={s.header}>
                            <p>{`Секция `}<span>{`${el.name}`}</span></p>
                            <div>
                                <ArrowTools className={s.arrow_tools} onClick={() => toggleHide(el.id)}/>
                                <MoreVertical className={s.more_content} onClick={() => handleMenu(el.id)}/>
                                <MenuMini
                                    items={section_items}
                                    open={openMenu && idMenu === el.id}
                                    close={setOpenMenu}
                                    section
                                />
                            </div>
                        </div>
                        {
                            blocksInSection?.map((block: any) => {
                                const block_items = [
                                    {
                                        icon: edit_icon,
                                        text: "Редактировать",
                                        callback: () => toggleBlockModal(el, block)
                                    },
                                    {icon: deleteIcon2, text: "Удалить", callback: () => handleDelBlock(block)},
                                ]

                                if (block.type === "text" || block.type === "link") {
                                    return (
                                        <div key={block.id} className={`${s.block} anchor-${block.id}`}>
                                            <header>
                                                <div className={s.info_block}>
                                                    <div className={s.header_item}>
                                                        <p className={s.title}>{`Тип: `}</p>
                                                        <p className={s.value}>{block.type}</p>
                                                    </div>

                                                    <div className={s.header_item}>
                                                        <p className={s.title}>{`ID: `}</p>
                                                        <p className={s.value}>{block.id}</p>
                                                    </div>

                                                    {block.text ?
                                                        <div className={s.header_item}>
                                                            <p className={s.title}>{`Псевдо: `}</p>
                                                            <p className={s.value}>{block.text}</p>
                                                        </div> : null
                                                    }
                                                    {block.link ?
                                                        <div className={s.header_item}>
                                                            <p className={s.title_link}>{`Ссылка: `}</p>
                                                            <p className={s.value}>{block.link}</p>
                                                        </div> : null
                                                    }
                                                    {block.marker ?
                                                        <div className={s.header_item}>
                                                            <p className={s.title}>{`Маркер: `}</p>
                                                            <p className={s.value}>{block.marker}</p>
                                                        </div> : null
                                                    }
                                                </div>
                                            </header>
                                            <div className={s.content}>
                                                <div className={`${block.type === "link" ? s.link_block: s.text_block}`}>
                                                    <p>{terms?.data?.data?.find((tm: any) => tm.pseudo === block.text && tm.lang_id === langState)?.value}</p>
                                                </div>
                                                <div className={s.lang_block}>
                                                    {
                                                        lang?.data?.data?.map((el: any) => {
                                                            return (
                                                                <div key={key()} className={`${s.lang_item} ${(el.id === langState) && s.lang_item_active}` }
                                                                     onClick={() => setLangState(el.id)}>
                                                                    <div className={s.lang}>{el.key}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={s.tools}>
                                                <MoreVertical
                                                    className={s.more_content}
                                                    onClick={() => handleMenu(block.id)}
                                                />
                                                <MenuMini
                                                    items={block_items}
                                                    open={openMenu && idMenu === block.id}
                                                    close={setOpenMenu}
                                                />
                                            </div>
                                        </div>
                                    )
                                } else if (block.type === 'image' || block.type === 'video') {

                                    const files = media.data.data.filter((el: any) => {
                                        return el.pseudo === block.file ? el.path : false
                                    });

                                    const images_items = [
                                        {
                                            icon: edit_icon,
                                            text: "Редактировать",
                                            callback: () => toggleBlockModal(el, block, files)
                                        },
                                        {icon: deleteIcon2, text: "Удалить", callback: () => delBlock(block)},
                                    ]

                                    return (
                                        <div key={block.id} className={`${s.block} anchor-${files?.[0]?.id}`}>
                                            <header>
                                                <div className={s.info_block}>
                                                    <div className={s.header_item}><span
                                                        className={s.title}>{`Type: `}</span><span
                                                        className={s.value}>{block.type}</span></div>
                                                    <div className={s.header_item}><span
                                                        className={s.title}>{`ID: `}</span><span
                                                        className={s.value}>{files?.[0]?.id}</span></div>
                                                    <div className={s.header_item}><span
                                                        className={s.title}>{`Group: `}</span><span
                                                        className={s.value}>{files?.[0]?.group}</span></div>
                                                    {block.type === 'image' ? <div className={s.header_item}><span
                                                        className={s.title}>{`alt: `}</span><span
                                                        className={s.value}>{files?.[0]?.alt}</span></div> : null}
                                                    {block.marker ? <div className={s.header_item}><span
                                                        className={s.title}>{`Marker: `}</span><span
                                                        className={s.value}>{block.marker}</span></div> : null}
                                                </div>
                                            </header>
                                            <div className={s.content}>
                                                <div className={s.images_content}>
                                                    {langs?.filter((ln: any) => files.find((el: any) => el.langkey === ln.key)?.link)?.map((ln: any, index: number) => (
                                                        <div className={s.image_item_wrapper} key={index}>
                                                            <div
                                                                className={`${s.header} ${files.find((el: any) => el.langkey === ln.key)?.default_image ? s.color : ""}`}>
                                                                <span className={s.language}>{ln.key}</span>
                                                                {
                                                                    files.find((el: any) => el.langkey === ln.key)?.default_image &&
                                                                    <span>| Default</span>
                                                                }
                                                            </div>
                                                            {
                                                                block.type === 'image' ?
                                                                    <div className={s.image_wrapper} style={{
                                                                        backgroundImage: `url(${files.find((el: any) => el.langkey === ln.key)?.link})`
                                                                    }}></div>
                                                                    :
                                                                    <div className={s.image_wrapper}>
                                                                        <video
                                                                            playsInline
                                                                            src={files.find((el: any) => el.langkey === ln.key)?.link}
                                                                            controls={true}
                                                                            style={{width: '100%', height: '100%'}}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={s.tools}>
                                                <MoreVertical
                                                    className={s.more_content}
                                                    onClick={() => handleMenu(block.id)}
                                                />
                                                <MenuMini
                                                    items={images_items}
                                                    open={openMenu && idMenu === block.id}
                                                    close={setOpenMenu}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        <PrimaryButton
                            type="button"
                            text={"Добавить блок"}
                            onClick={() => toggleBlockModal(el)}
                            icon={<Plus/>}
                            className={s.button}
                        />
                    </div>
                </div>
            )
        })
    }

    function toggleBlockModal(el: any, block: any = false, file: any = undefined) {
        let terms_block: any[] = [];
        terms.data.data.forEach((el: any) => {
            if (el.pseudo === block?.text) terms_block[el.lang_id] = el
        });
        const values = {
            'type': block?.type,
            'link': block?.link ? block?.link : false,
            'pseudo': block.text || file?.[0]?.pseudo,
            'block_id': block.id,
            'file': file,
            'terms_block': terms_block,
            'marker': block.marker
        }
        setBlocksModal((prev: any) => {
            return {state: !prev.state, section: el, values: values, lang_data: lang.data.data}
        })
    }

    function toggleSectionModal(el: any = false) {
        setSectionModal({state: true, el: el})
    }

    async function delPage(page: any) {
        const data = page ? page : pageState;

        console.log(data);

        const result: any = await deletePage(data.id);
        if (result.error?.data.message === 1000) {
            openStatus('warning', 'К сожалению, у вас нет необходимых прав');
        } else if (result.data.message === 33) {
            openStatus('success', 'Операция успешно выполнена!');
            // @ts-ignore
            dispatch(pagesApi.util.resetApiState(['Pages']));
            setPageState(false);
        }
    }

    async function delSection(el: any) {
        const result: any = await deleteSection({section: el.id, page_id: pageState.id});
        if (result.error?.data.message === 1000) {
            openStatus('warning', 'К сожалению, у вас нет необходимых прав');
        } else if (result.data.message === 37) {
            openStatus('success', 'Операция успешно выполнена!');
            // @ts-ignore
            dispatch(sectionApi.util.resetApiState(['Pages']))
            // @ts-ignore
            dispatch(sectionApi.util.resetApiState(['Section']));
        }
    }

    const delBlock = async (block: any) => {
        const res: any = await deleteBlock([block.id]);
        if (res.error?.data.message === 1000) {
            openStatus('warning', 'К сожалению, у вас нет необходимых прав');
        } else if (res.data.message === 40) {
            openStatus('success', 'Операция успешно выполнена!');
            dispatch(sectionApi.util.invalidateTags(['Section']));
        }
    };

    const handleDelPage = (page: any) => {
        setName(page ? page.name : pageState);
        setModalElement(page);
        setShowModalPage(true);
    }

    const handleDelSection = (el: any) => {
        setName(el.name);
        setModalElement(el);
        setShowModalSection(true);
    }

    const handleDelBlock = (block: any) => {
        setName(block.marker);
        setModalElement(block);
        setShowModalBlock(true);
    }

    const pages_view = pages?.data?.data?.map((el: any) => {
        const menu_items = [
            {icon: edit_icon, text: "Редактировать", callback: () => setPagesModal({state: true, el: el})},
            {icon: deleteIcon2, text: "Удалить", callback: () => handleDelPage(el)},
        ]
        return (
            <div
                key={el.id}
                className={`${s.page_item} ${pageState?.id === el.id ? s.active : ''}`}
                onClick={() => setPageState(pages?.data?.data?.find((page: any) => page?.id === el?.id))}
            >
                <p>
                    {el?.name}
                </p>
                <MoreVertical className={s.more_page} onClick={() => handleMenu(el.id)}/>
                <MenuMini
                    items={menu_items}
                    open={openMenu && idMenu === el.id}
                    close={setOpenMenu}
                    page
                />
            </div>
        )
    })

    if (pagesModal.state) {
        return (
            <PagesModal
                close={setPagesModal}
                el={pagesModal.el}
                pageState = {pageState}
            />
        )
    }

    if (sectionModal.state) {
        return (
            <SectionModal
                close={setSectionModal}
                el={sectionModal.el}
                pageState = {pageState}
            />
        )
    }

    if (blocksModal.state) {
        return (
            <BlocksModal
                close={setBlocksModal}
                section={blocksModal.section}
                values={blocksModal.values}
                lang_data={blocksModal.lang_data}
            />
        )
    }

    const description = (
        <>
            Вы уверены, что хотите удалить <span>{name}</span> ? <br />
            Все ее содержимое будет удалено вместе с ней!
        </>
    );

    return (
        <div className={s.pages}>
            <HeaderSection
                title={"Страницы"}
                buttonText={"Добавить новую страницу"}
                icon={<Plus/>}
                onClick={() => setPagesModal({state: true, el: false})}
            />

            {pagesModal.state ?
                <PagesModal
                    close={setPagesModal}
                    el={pagesModal.el}
                    pageState = {pageState}
                /> :

                <div className={s.contents}>
                    <div className={s.top}>
                        {pages_view}
                    </div>

                    <div className={s.left}>
                        {sections_view}
                        <PrimaryButton
                            type="button"
                            text={"Добавить новую секцию"}
                            onClick={() => toggleSectionModal()}
                            icon={<Plus/>}
                            className={s.button}
                        />
                    </div>
                </div>
            }

            <ModalGeneral
                showModal={showModalPage}
                setShowModal={setShowModalPage}
                image={questionMark}
                buttonRight={() => delPage(modalElement)}
                title={"Удалить страницу?"}
                textLeftButton={"Отменить"}
                textRightButton={"Удалить"}
                description={description}
            />
            <ModalGeneral
                showModal={showModalSection}
                setShowModal={setShowModalSection}
                image={questionMark}
                buttonRight={() => delSection(modalElement)}
                title={"Удалить cекцию?"}
                textLeftButton={"Отменить"}
                textRightButton={"Удалить"}
                description={description}
            />
            <ModalGeneral
                showModal={showModalBlock}
                setShowModal={setShowModalBlock}
                image={questionMark}
                buttonRight={() => delBlock(modalElement)}
                title={"Удалить блок?"}
                textLeftButton={"Отменить"}
                textRightButton={"Удалить"}
                description={description}
            />
        </div>
    )
}
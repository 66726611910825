type Options = string[];

export function PreparationModuleData(
    data: any,
    options: Options
): any | null {
    if (data === null || data === undefined) {
        return null;
    }

    if (Array.isArray(data)) {
        data.forEach(el => {
            PreparationModuleData(el, options);
        });
    } else if (typeof data === 'object' && data !== null) {
        for (const el in data) {
            if (Array.isArray(data[el])) {
                data[el].forEach((nestedEl: any) => {
                    PreparationModuleData(nestedEl, options);
                });
            }
            if (options.includes(el)) {
                delete data[el];
            }
        }
    }
    return data;
}

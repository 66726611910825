import Swal from "sweetalert2";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useDeletePostMutation, useUpdateModuleMarkerMutation} from "../../../../redux/apis/modulesApi";
import {Preloader} from "../../../../components/Preloader/Preloader";
import {Toast} from "../../../../utils/toast";
import {getSequenceTypes} from "../../../../utils/getSequenceTypes";
import s from './ModuleBlock.module.scss';
import {ModuleItem} from "../ModuleItem/ModuleItem";

type ModalBlockType = {
    post: any;
    types: any;
    setModal: any;
    inner?: boolean;
    setNewModuleData: any;
    setViewMode: any;
    parrent_id: number;
    parrent_type?: any;
    index: number;
    langState: any;
    setLangState: any;
}

export const ModuleBlock = ({
                                post,
                                types,
                                setModal,
                                inner = false,
                                setNewModuleData,
                                setViewMode,
                                parrent_id,
                                parrent_type,
                                index,
                                langState,
                                setLangState
}: ModalBlockType) => {
    const [markerData, setMarkerData] = useState(post?.marker)
    const [markerFlag, setMarkerFlag] = useState(false)
    const [currentMarkerData, setCurrentMarkerData] = useState(post.marker)
    const langs = useSelector((store: any) => store.main.langs)
    const content: any = useRef();
    const item: any = useRef();
    const marker: any = useRef();
    const current_type = types.find((type: any) => type.title === post.title)

    const [updateMarker, {isLoading: isUpdateMarkerLoading}] = useUpdateModuleMarkerMutation()
    const [deletePost, {isLoading}] = useDeletePostMutation();
    const [pageSettingsModalState, setPageSettingsModalState] = useState(false)

    useEffect(()=> {
        if(inner) item.current.style.cssText= `width: 99%; margin: 0 auto 10px;`
    },[]);

    useEffect(()=>{
        if(markerData === currentMarkerData){
            if(marker.current){
                marker.current.style.opacity = 0.4
                setMarkerFlag(false)
            }
        }
        else {
            marker.current.style.opacity = 1
            setMarkerFlag(true)
        }
    },[markerData, currentMarkerData]);

    if(isLoading) return <Preloader/>

    const module_items_view = post.data?.map((el: any, index: number) => {
        // return <ModuleItem key={index} item={el} setModal={setModal} types={types} setViewMode={setViewMode} setNewModuleData={setNewModuleData} parrent_id={post.id} parrent_type={current_type} index={index} langState={langState} setLangState={setLangState}/>
    })

    async function deleteBlock(){
        Swal.fire({
            title: 'Видалити блок?',
            text: `Ви впевнені що хочете видалити блок? Разом з ним буде видалено весь його контент!`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'question',
            customClass: {
                actions: 'modal',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if(result.isConfirmed){
                const response: any = await deletePost(post.id);

                if(response.data){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
                else if(response.error?.data?.message === 1000){
                    Swal.fire({
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                            actions: 'modal',
                            cancelButton: 'order-1 right-gap',
                            confirmButton: 'order-2',
                            denyButton: 'order-3',
                        }
                    })
                    return;
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
            }
        })
    }

    function addNewBlock(){
        setViewMode(false)
        const res = getSequenceTypes(post.id, types, post.title, langs, parrent_id, index, true)
        setNewModuleData(res)
    }

    async function saveMarkerData(){
        const response = await updateMarker({
            post_id: post.id,
            markerData: markerData
        })
        if(response.data?.message === 30){
            setCurrentMarkerData(markerData)
            Toast.fire({icon: 'success', title: 'Успіх'});
        }
        else{
            Toast.fire({icon: 'error', title: 'Помилка'});
        }
    }

    return(
        <div className={`${s.item} anchor-${post.id}`} ref={item}>
            <header>
                { (current_type.type === "array" || current_type.type === "object") &&
                    <div className={s.marker_wrapper} ref={marker}>
                        <span className={s.marker_title}>Marker:</span>
                        <input value={markerData} onChange={(e) => {setMarkerData(e.target.value)}} type="text" />
                        {/*{markerFlag ?*/}
                        {/*    isUpdateMarkerLoading? <div className={s.preloader_wrapper}>{<LoadingIcon/>}</div> : <span onClick={saveMarkerData} className={s.save}>save</span>*/}
                        {/*    :null}*/}
                    </div>
                }
                <span
                    // style={post?.isArray? {color: "#FFF", fontSize: "14px"} : null}
                >
                    {`${post.isArray? "[":""}${post.title}${post.isArray? "]":""}`}
                </span>
                {
                    current_type.type !== 'array' &&
                    <div className={s.tools}>
                        {/*{*/}
                        {/*    current_type.type === 'page' &&*/}
                        {/*    <span className={s.delete_icon} onClick={()=>setPageSettingsModalState({id: post.id, values: post.params})}>*/}
                        {/*        <SettingIcon/>*/}
                        {/*    </span>*/}
                        {/*}*/}
                        {/*<span className={s.delete_icon} onClick={()=>deleteBlock()}><DeleteIcon/></span>*/}
                    </div>
                }
            </header>
            <div className={s.content} ref={content}>
                {
                    post.data?
                        <div>
                            {module_items_view}
                            {post.isArray? <div className="add_module_button" onClick={() => addNewBlock()}>{`Додати ${post.title}`}<span>&#11015;</span></div> : null}
                        </div> :
                        <ModuleItem
                            key={post.id}
                            item={post}
                            setModal={setModal}
                            types={types}
                            setViewMode={setViewMode}
                            parrent_type={current_type}
                            setNewModuleData={setNewModuleData}
                            langState={langState}
                            setLangState={setLangState}
                        />
                }
            </div>
            {/*{pageSettingsModalState? <PageSettingsModal item={pageSettingsModalState.id} data={pageSettingsModalState.values} close={()=>setPageSettingsModalState(false)} type="module"/> : null}*/}
        </div>
    )
}
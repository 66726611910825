type Lang = {
    id: number;
    value?: string;
};

type Type = {
    id: number;
    title: string;
    type: "text" | "link" | "image" | "video" | "block";
    sequence?: number[];
    id_allowed?: number[];
};

type Sequence = {
    title?: string;
    id_type?: number;
    id_parent?: number;
    id_post?: number;
    index?: number;
    values?: any[];
    isArray?: boolean;
};

type Result = {
    title: string;
    id: number;
    values: any[];
    isBlock?: boolean;
    isArray?: boolean;
    id_type: number;
    moduleId?: string;
    editId?: string;
};

export function getSequenceTypes(
    id_post: number,
    types: Type[],
    title: string,
    langs: Lang[],
    parent_id: number,
    index: number,
    isArray = false
): Sequence {
    const current_type = types.find(type => type.title === title);
    if (!current_type) throw new Error("Type with specified title not found");

    let isParent = false;
    types.forEach(el => {
        if (el.id_allowed?.includes(current_type.id)) isParent = true;
    });

    const sequence: Sequence = {
        title: title,
        id_type: current_type.id,
        id_parent: isParent ? parent_id : 0,
        id_post: isParent ? undefined : id_post,
        index: isParent ? index + 1 : undefined,
        values: [],
        isArray
    };

    sequence.values = current_type.sequence?.map((el, index) => {
        const el_type = types.find(type => type.id === el);
        const new_date = `${Math.floor(Math.random() * 999999999999)}`;

        if (el < 0 || el_type?.sequence?.length) {
            const block_type = types.find(type => type.id === Math.abs(el));
            if (!block_type) throw new Error("Block type not found");

            const result: Result = {
                title: block_type.title,
                id: block_type.id,
                values: getTypes(id_post, block_type, types, langs, index),
                isBlock: true,
                isArray: el < 0,
                id_type: Math.abs(el),
                moduleId: new_date
            };
            return result;
        }

        const return_type = types.find(type => type.id === el);
        if (!return_type) throw new Error("Return type not found");

        let values: any;
        if (return_type.type === "text" || return_type.type === "link") {
            values = langs.map(ln => ({ lang_id: ln.id, value: "" }));
        } else if (return_type.type === "image" || return_type.type === "video") {
            values = { pseudo: new_date };
        }

        const result: Result = {
            ...return_type,
            id_type: return_type.id,
            values: values,
            editId: new_date
        };
        return result;
    }) ?? [];

    return sequence;
}

function getTypes(
    id_post: number,
    type: Type,
    types: Type[],
    langs: Lang[],
    index?: number
): Result[] {
    const sequence = type.sequence ?? [];
    return sequence.map((el, index) => {
        const el_type = types.find(type => type.id === el);
        const new_date = `${Math.floor(Math.random() * 999999999999)}`;

        if (el < 0 || el_type?.sequence?.length) {
            const block_type = types.find(type => type.id === Math.abs(el));
            if (!block_type) throw new Error("Block type not found");

            const result: Result = {
                title: block_type.title,
                id: block_type.id,
                values: getTypes(block_type.id, block_type, types, langs),
                isBlock: true,
                isArray: el < 0,
                id_type: Math.abs(el),
                moduleId: new_date
            };
            return result;
        }

        const return_type = types.find(type => type.id === el);
        if (!return_type) throw new Error("Return type not found");

        let values: any;
        if (return_type.type === "text" || return_type.type === "link") {
            values = langs.map(ln => ({ lang_id: ln.id, value: "" }));
        } else if (return_type.type === "image" || return_type.type === "video") {
            values = { pseudo: new_date };
        }

        const result: Result = {
            ...return_type,
            id_type: return_type.id,
            values: values,
            editId: new_date
        };
        return result;
    });
}

export async function Timeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function random(max: number): number {
    return Math.floor(Math.random() * max);
}

import {ModuleBlock} from "../ModuleBlock/ModuleBlock";
import s from './ModuleItem.module.scss';
import {ModuleTextItem} from "../ModuleTextItem/ModuleTextItem";
import {ModuleFileItem} from "../ModuleFileItem/ModuleFileItem";

type ModuleItemProps = {
    item: any;
    setModal: any;
    types?: any;
    setViewMode: any;
    setNewModuleData: any;
    parrent_id?: any;
    parrent_type: any;
    index?: any;
    langState: any;
    setLangState: any;
}

export const ModuleItem = ({
                               item,
                               setModal,
                               types,
                               setViewMode,
                               setNewModuleData,
                               parrent_id,
                               parrent_type,
                               index,
                               langState,
                               setLangState
}: ModuleItemProps) => {
    if(item.sequence?.length){
        return <ModuleBlock
            post={item}
            setModal={setModal}
            inner={true}
            types={types}
            setViewMode={setViewMode}
            setNewModuleData={setNewModuleData}
            parrent_id={parrent_id}
            parrent_type={parrent_type}
            index={index}
            langState={langState}
            setLangState={setLangState}
        />
    }
    else{
        return(
            <div className={`${s.item} anchor-${item?.id}`}>
                <div className={s.content}>

                    {item.type === "image" || item.type === "video" ?
                        <ModuleFileItem data={item} setModal={setModal}/> :
                        <ModuleTextItem
                            data={item}
                            setModal={setModal}
                            langState={langState}
                            setLangState={setLangState}
                        />
                    }
                </div>
            </div>
        )
    }
}
import React, {useEffect, useState} from 'react';
import s from './DropDown.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {Field, FormikErrors, FormikValues} from "formik";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillEditor from "../QuillEditor/QuillEditor";

type DropDown = {
    langs: any;
    errors: any;
    touched: any;
    kind: string;
    label: string;
}

export const DropDown = ({ langs, errors, touched, kind, label}: DropDown) => {
    const [hideStates, setHideStates] = useState<{ [key: string]: boolean }>({});

    const toggleHide = (key: string) => {
        setHideStates(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    return (
        <div className={s.dropdown}>
            {/*<label>Языки</label>*/}
            {/*<div className={s.input_container}>*/}
            {/*    <div className={s.input_content}>*/}
            {/*        {langs?.map((el: any) => (*/}
            {/*            <div className={s.value}>*/}
            {/*                {el.key}*/}
            {/*                <Cross className={s.icon_delete} />*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}

            {/*    <button type={"button"} className={s.button}>*/}
            {/*        Добавить*/}
            {/*        <Plus className={s.icon_add} />*/}
            {/*    </button>*/}
            {/*</div>*/}

            {langs?.map((v: any) => (
                <div className={`${s.content} ${!hideStates[v.key] && s.hide}`}>
                    <div className={s.content_head}>
                        <p>{label} <span>{v.key}</span></p>

                        <ArrowTools onClick={() => toggleHide(v.key)} className={s.arrow}/>
                    </div>

                    <Field name={`${kind}-${v.key}`} component={QuillEditor} />

                    <span className={s.error_text}>{touched?.[`${kind}-${v.key}`] ? errors[`${kind}-${v.key}`] : null}</span>
                </div>
            ))}
        </div>
    )
}
import React from 'react';
import s from "./ModalGeneral.module.scss";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import {Input} from "../Input/Input";
import {Field, Form, Formik} from "formik";
import * as yup from "yup";

type Modal = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    image?: any;
    buttonRight: any;
    title: string;
    textLeftButton: string;
    textRightButton: string;
    description?: any;
    field?: boolean;
}

export const ModalGeneral = ({
     showModal,
     setShowModal,
     image, buttonRight,
     title,
     textLeftButton,
     textRightButton,
     description,
     field
}: Modal) => {

    const validationSchema = yup.object().shape({
        key: yup.string()
            .required('Обязательное поле')
            .min(2, 'Введите не менее 2 символов'),
    });

    if (!showModal) {
        return null;
    }

    const handleClose = () => {
        buttonRight();
        setShowModal(false)
    }

    return (
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
                <h1>{title}</h1>
                {image && <div className={s.image_wrapper}>
                    <img src={image} alt={"icon"} />
                </div>}

                {description && <p className={s.modal_description}>{description}</p>}

                {field &&
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{ key: '' }}
                        onSubmit={(values) => buttonRight(values.key)}
                    >
                        {({ setFieldValue, errors, touched, values }) => (
                            <Form className={s.form}>
                                <Input
                                    errors={errors.key}
                                    touched={touched.key}
                                    name={"key"}
                                    label="Ключ"
                                    placeholder="Введите ключ"
                                    onClick={() => setFieldValue("key", "")}
                                />
                                <div className={s.buttons_wrapper}>
                                    <SecondaryButton
                                        type="button"
                                        text={textLeftButton}
                                        onClick={() => setShowModal(false)}
                                    />
                                    <PrimaryButton
                                        disabled={!values.key}
                                        type="submit"
                                        text={textRightButton}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {!field && <div className={s.buttons_wrapper}>
                    <SecondaryButton
                        type="button"
                        text={textLeftButton}
                        onClick={() => setShowModal(false)}
                    />
                    <PrimaryButton
                        type="button"
                        text={textRightButton}
                        onClick={handleClose}
                    />
                </div>}
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import {useAddUserMutation, useUpdateUserMutation} from "../../redux/apis/userApi";
import {useCrypto} from "utils/crypto";
import s from "./AddUser.module.scss";
import { Form, Formik} from "formik";
import * as yup from 'yup';
import {Back} from "../Back/Back";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import {Input} from "../Input/Input";
import {useStatusContext} from "../StatusProvider";

type AddUser = {
    close: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    user?: any;
}

export const AddUser = ({ close, user }: AddUser) => {
    const [isOpenCheckbox, setIsOpenCheckbox] = useState<boolean>(false);
    const { openStatus } = useStatusContext();

    console.log(user?.permissions);

    const [permissions, setPermissions] = useState({
            "terms": {
                "view": user?.permissions?.terms?.view,
                "edit": user?.permissions?.terms?.edit
            },
            "pages": {
                "view": user?.permissions?.pages?.view,
                "create": user?.permissions?.pages?.create,
                "edit": user?.permissions?.pages?.edit,
                "delete": user?.permissions?.pages?.delete
            },
            "modules": {
                "view": user?.permissions?.modules?.view,
                "create": user?.permissions?.modules?.create
            },
            "langs": { "view": user?.permissions?.langs?.view }
        },
    );

    useEffect(()=>{
        if (user) setPermissions(user?.permissions)
    },[]);

    const [addUser] = useAddUserMutation();
    const [updUser] = useUpdateUserMutation();

    const initialValues = {
        email: user ? user.email : '',
        name: user ? user?.info?.name:'',
        password: user ? "WQf89D11Ixp55a" : '',
        confirmPassword: user ? "WQf89D11Ixp55a" : '',
        role: user ? user.role : '',
    }

    const validationSchema = yup.object().shape({
        email: yup.string().required(`Обязательное поле`).email('Некорректный email'),
        password: yup
            .string()
            .required('Обязательное поле')
            .min(14, 'Пароль должен содержать не менее 14 символов')
            .matches(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
            .matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру'),
        confirmPassword: yup.string().required(`Обязательное поле`).oneOf([yup.ref('password')], 'Пароли не совпадают'),
        name: yup.string().required(`Обязательное поле`),
        role: yup.string().required(`Обязательное поле`),
    });

    async function Registration(values: any){
        const email = values.email;
        const password = useCrypto(values.password);
        const name = values.name;
        const role = values.role;

        const result = await addUser({email: email, password: password, name: name, permissions: permissions});

        if (result.data) {
            if(result.data.message === 1) {
                openStatus('error', `Электронная почта ${result.data.data} уже зарегистрирована`);
            }
            if (result.data.message === 7) {
                close(false);
                openStatus('success', 'Операция успешно выполнена!');
            }
        } else {
            openStatus('error', "Произошла ошибка");
        }
    }

    async function UpdateUser(values: any){
        const user_id = user.id;
        const email = values.email;
        const name = values.name;
        const role = values.role;

        const result = await updUser({user_id: user_id, email: email, name: name, permissions: permissions});

        if (result.data) {
             if (result.data.message === 7) {
                 close(false);
                 openStatus('success', 'Операция успешно выполнена!');
             }
        } else {
            openStatus('error', 'Произошла ошибка');
        }
    }

    function selectPermission(el: any, value: string, group: string) {
        console.log(value);
        console.log(group);

        const isChecked = el.checked;

        setPermissions(prev => {
            const newState = prev ? JSON.parse(JSON.stringify(prev)) : { [group]: {} };
            newState[group] = newState[group] || {};

            if (group === "site") {
                newState[group]["view"] = value === "view" ? isChecked : false;
                newState[group][value] = isChecked && value !== "view";
            }

            else if (group === "pages") {
                newState[group][value === "onlyView" ? "view" : value] = isChecked;
            } else {
                newState[group][value] = isChecked;
            }

            const resetOtherPermissions = () => {
                Object.keys(newState[group]).forEach(key => {
                    if (key !== value && key !== "view") {
                        newState[group][key] = false;
                    }
                });
            };

            if (isChecked) {
                const shouldReset =
                    (group === "modules" && value !== "create") ||
                    (group === "terms" && value !== "edit") ||
                    (group === "pages" && !["view", "create", "edit", "delete"].includes(value));

                if (shouldReset) resetOtherPermissions();
                else newState[group]["view"] = true;
            }

            console.log(newState);

            return newState;
        });
    }

    return (
        <div className={s.add_user}>
            <Back close={close} text={"Назад к пользователям"} />

            <div className={s.form}>
                <h1>{user ? "Редактировать пользователя": "Добавить пользователя"}</h1>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        if (!user) Registration(values);
                        else UpdateUser(values);
                    }}
                >
                    {({errors, touched, setFieldValue}) => (
                        <Form className={s.auth_form}>
                            <div className={s.auth_form_border}>
                                <div className={s.auth_form_field}>
                                    <div className={s.form_row}>
                                        <Input
                                            label={"Электронная почта"}
                                            errors={errors.email}
                                            touched={touched.email}
                                            name={"email"}
                                            placeholder={"Введите электронную почту пользователя"}
                                            onClick={() => setFieldValue('email', '')}
                                        />

                                        <Input
                                            label={"Имя"}
                                            errors={errors.name}
                                            touched={touched.name}
                                            name={"name"}
                                            placeholder={"Введите имя пользователя"}
                                            onClick={() => setFieldValue('name', '')}
                                        />
                                    </div>

                                    <div className={s.form_row}>
                                        <Input
                                            label={"Пароль"}
                                            errors={errors.password}
                                            touched={touched.password}
                                            name={"password"}
                                            placeholder={"Введите пароль"}
                                            password
                                        />

                                        <Input
                                            label={"Подтвердите пароль"}
                                            errors={errors.confirmPassword}
                                            touched={touched.confirmPassword}
                                            name={"confirmPassword"}
                                            placeholder={"Подтвердите пароль"}
                                            password
                                        />
                                    </div>

                                    <Input
                                        label={"Роль"}
                                        errors={errors.role}
                                        touched={touched.role}
                                        name={"role"}
                                        placeholder={"Введите роль пользователя"}
                                        onClick={() => setFieldValue('role', '')}
                                    />
                                </div>

                                <div className={s.access}>
                                    <h2>Права доступа</h2>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>Управление модулями</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-modules"
                                                value="view"
                                                defaultChecked={permissions?.["modules"]?.["view"]}
                                                onChange={(ev) =>
                                                    selectPermission(ev.target, ev.target.value, "modules")
                                                }
                                            />
                                            Только просмотр
                                        </label>
                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-modules"
                                                value="create"
                                                defaultChecked={permissions?.["modules"]?.["create"]}
                                                onChange={(ev) =>
                                                    selectPermission(ev.target, ev.target.value, "modules")
                                                }
                                            />
                                            Редактирование
                                        </label>
                                    </div>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>Редактирование языков</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-langs"
                                                value="edit"
                                                defaultChecked={permissions?.["langs"]?.["view"]}
                                                onChange={(ev) =>
                                                    selectPermission(ev.target, ev.target.value, "langs")
                                                }
                                            />
                                            Да
                                        </label>
                                    </div>


                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>Управление секциями</p>
                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="view"
                                                defaultChecked={permissions?.["pages"]?.["view"]}
                                                onChange={(ev) =>
                                                {
                                                    setIsOpenCheckbox(false);
                                                    selectPermission(ev.target, "onlyView", "pages");
                                                }}
                                            />
                                            Только просмотр
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="view"
                                                defaultChecked={permissions?.["pages"]?.["edit"] || permissions?.["pages"]?.["create"] || permissions?.["pages"]?.["delete"]}
                                                onChange={(ev) =>
                                                {
                                                    setIsOpenCheckbox(true);
                                                    selectPermission(ev.target, ev.target.value, "pages");
                                                }}
                                            />
                                            Редактирование
                                        </label>

                                        {(isOpenCheckbox || (permissions?.["pages"]?.["edit"] || permissions?.["pages"]?.["create"] || permissions?.["pages"]?.["delete"])) && (
                                            <div className={s.checkbox_bottom}>
                                                <label className={s.label}>
                                                    <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value="create"
                                                        defaultChecked={permissions?.["pages"]?.["create"]}
                                                        onChange={(ev) =>
                                                            selectPermission(ev.target, ev.target.value, "pages")
                                                        }
                                                    />
                                                    Создание
                                                </label>
                                                <label className={s.label}>
                                                    <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value="edit"
                                                        defaultChecked={permissions?.["pages"]?.["edit"]}
                                                        onChange={(ev) =>
                                                            selectPermission(ev.target, ev.target.value, "pages")
                                                        }
                                                    />
                                                    Редактирование
                                                </label>
                                                <label className={s.label}>
                                                    <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value="delete"
                                                        defaultChecked={permissions?.["pages"]?.["delete"]}
                                                        onChange={(ev) =>
                                                            selectPermission(ev.target, ev.target.value, "pages")
                                                        }
                                                    />
                                                    Удаление
                                                </label>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className={s.wrap_buttons}>
                                <SecondaryButton
                                    text={"Отменить"}
                                    type={"button"}
                                    onClick={() => close(false)}
                                />
                                <PrimaryButton text={"Подтвердить"} type={"submit"} />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
import React from 'react';
import { Field, FieldProps } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps {
    field: FieldProps['field'];
    form: FieldProps['form'];
}

const QuillEditor: React.FC<QuillEditorProps> = ({ field, form }) => {
    const handleChange = (content: string) => {
        form.setFieldValue(field.name, content);
    };

    return (
        <ReactQuill
            value={field.value}
            onChange={handleChange}
            theme="snow"
            modules={{
                toolbar: false
            }}
            readOnly={false}
        />
    );
};

export default QuillEditor;

import React from 'react';
import cn from 'classnames';
import s from './Status.module.scss';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import {useStatusContext} from "../StatusProvider";

export const Status = () => {
    const { status, description, show, closeStatus } = useStatusContext();

    if (!show) return null;

    const iconsStatus: any = {
        success: <Success />,
        warning: <Warning />,
        error: <Error />
    };

    const titleStatus: any = {
        success: "Успішно",
        warning: "Попередження",
        error: "Помилка"
    };

    const classShadow = () => cn(s.shadow, {
        [s.shadow_success]: status === "success",
        [s.shadow_warning]: status === "warning",
        [s.shadow_error]: status === "error"
    });

    return (
        <div className={cn(s.status)}>
            <div className={classShadow()}></div>

            <div className={s.wrap_icon}>
                {iconsStatus[status || 'success']}
            </div>

            <div className={s.text_block}>
                <p>{titleStatus[status || 'success']}</p>
                <span>{description}</span>
            </div>
        </div>
    );
};

import React from 'react';
import s from './Main.module.scss';
import {TabBar} from "components/TabBar/TabBar";
import {Route, Routes} from "react-router-dom";
import {Dashboard} from "../Dashboard/Dashboard";
import {Users} from "../Users/Users";
import {Permissions} from "utils/permissions";
import {Language} from "../Language/Language";
import {Pages} from "../Pages/Pages";
import {useGetModulesQuery} from "../../redux/apis/modulesApi";
import {Module} from "../Module/Module";

export const Main = () => {
    const modules = useGetModulesQuery({});

    const routes_view = modules.data?.map((el: any) => {
        return (
            <Route
                key={el.id}
                path={`/module/${el.type}`}
                element={<Permissions page="modules" type="view"
            >
                <Module id={el.id} title={el.title}/>
            </Permissions>}/>
        )
    })

    return (
        <div className={s.main}>
            <TabBar />
            <section className={s.main_content}>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/language" element={<Permissions page="langs" type="view"><Language /></Permissions>}/>
                    <Route path="/pages" element={<Permissions page="pages" type="view"><Pages /></Permissions>}/>
                    {routes_view}
                </Routes>
            </section>
        </div>
    )
}
import React, {useEffect, useRef, useState} from "react";
import s from './Select.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as Check } from 'assets/icons/check.svg';

type SelectedOption = string | null;

type Select = {
    values: any[];
    change?: any;
    refSelect: any;
    defaultValue?: any;
}

export const Select = ({ values, change, refSelect, defaultValue}: Select) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<SelectedOption>(defaultValue && defaultValue);
    const selectRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = (): void => setIsOpen(!isOpen);

    const selectOption = (option: string): void => {
        setSelectedOption(option);
        setIsOpen(false);

        if (change) {
            change(option);
        }

    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className={s.custom_select} ref={selectRef}>
            <div
                className={`${s.selected}
                ${isOpen && s.selected_active}`}
                onClick={toggleDropdown}
                ref={refSelect}
            >
                {selectedOption || "Выберите значения"}
                <ArrowTools />
            </div>
            {isOpen && (
                <ul className={s.options}>
                    {values?.map((option: any, index: number) => (
                        <li
                            key={index}
                            onClick={() => selectOption(option.name ?? option.props.value)}
                            className={s.option}
                        >
                            {option.name ?? option.props.value}
                            {selectedOption === (option.name ?? option.props.value) && <Check />}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
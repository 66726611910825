import {HeaderSection} from "components/HeaderSection/HeaderSection";
import React, {useEffect, useState} from "react";
import s from './Language.module.scss';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {Table} from "components/Table/Table";
import {useDeleteLanguageMutation, useGetLangsQuery, useSetLanguageMutation} from "../../redux/apis/langApi";
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import questionMark from "assets/images/questionMark.webp";
import {Preloader} from "components/Preloader/Preloader";
import {useStatusContext} from "components/StatusProvider";
import {useLocation} from "react-router-dom";

interface ErrorData {
    message: number;
}

export const Language = () => {
    const [query, setQuery] = useState<string>('');
    const headers = ['Языки', 'Ключ', 'Статус'];
    const { data: languages  } = useGetLangsQuery({});
    const [filteredLanguages, setFilteredLanguages] = useState<any[]>();

    const location = useLocation();
    const { addLang: myAddLang, deleteLang: myDeleteLang, nameLang: myNameLang } = location.state || {};
    const [openModalAdd, setOpenModalAdd] = useState<boolean>(Boolean(myAddLang));

    const [setLanguage] = useSetLanguageMutation();
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(Boolean(myDeleteLang));
    const [deleteLanguage] = useDeleteLanguageMutation();
    const [name, setName] = useState<string>(myNameLang ? myNameLang: "");
    const [idLang, setLangId] = useState<number>(myDeleteLang ? myDeleteLang: null);
    const { openStatus } = useStatusContext();

    useEffect(() => {
        setFilteredLanguages(languages?.data);
    }, [languages]);

    if (languages?.isFetching) return <Preloader/>

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const statusLanguages = (status: boolean) => {
        if (status) {
            return <div className={s.active}>Активный</div>
        } else {
            return <div className={s.notActive}>Не активен</div>
        }
    }

    const handleAddLanguage = () => {
        setOpenModalAdd(true);
    }

    const handleDelete = (displayname: string, id: number) => {
        setLangId(id);
        setName(displayname);
        setOpenModalDelete(true);
    }

    const languages_view = filteredLanguages?.map((language: any) => {
        return (
            <tr key={language?.id}>
                <td>{language?.displayname}</td>
                <td>{language?.key}</td>
                <td>{statusLanguages(language?.active)}</td>
                <td className={s.icons}>
                    <span
                        className={s.tools_icon}
                        onClick={() => handleDelete(language?.displayname, language?.id)}
                    >
                        <Delete />
                    </span>
                    <span
                        className={s.tools_icon}
                        /*onClick={() => handleEdit(user)}*/
                    >
                        <Edit />
                    </span>
                </td>
            </tr>
        )
    })

    const deleteLang = async () => {
        const result = await deleteLanguage(idLang);
        if (result.data?.message === 24){
            openStatus('success', 'Операция успешно выполнена!');
        }
        setOpenModalDelete(false);
    }

    const handleSearch = () => {
        const results = languages?.data?.filter((language: any) =>
            (language.key && String(language.key).toLowerCase().includes(query.toLowerCase())) ||
            (language.displayname && String(language.displayname).toLowerCase().includes(query.toLowerCase()))
        );

        setFilteredLanguages(results);
    };

    async function addLang(value: string){
        const result = await setLanguage(value);
        const error = result.error as FetchBaseQueryError;
        const errorData = error?.data as ErrorData;

        if (errorData && 'message' in errorData && errorData.message === 1000) {
            openStatus('warning', 'К сожалению, у вас нет необходимых прав');
        }
        if (result?.data?.message === 21) {
            openStatus('error', `Язык ${result.data.data} уже существует`);
        }
        if (result?.data?.message === 22) {
            openStatus('success', 'Операция успешно выполнена!');
        }

        setOpenModalAdd(false);
    }

    const description = (
        <>
            Вы уверены, что хотите удалить язык <span>{name}</span> ? <br />
            Все его содержимое будет удалено вместе с ним!
        </>
    );

    return (
        <section className={s.languages}>
            <HeaderSection
                title={"Языки"}
                buttonText={"Добавить язык"}
                icon={<Plus/>}
                onClick={handleAddLanguage}
                description={"Выберите язык по умолчанию и другой язык, который будет более понятен пользователю и удобен в использовании."}
            />
            <Table
                title="Ваши языки"
                placeholder="Поиск (по ключу или языку)"
                query={query}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                handleSearch={handleSearch}
                headers={headers}
                data={languages_view}
            />
            <ModalGeneral
                showModal={openModalAdd}
                setShowModal={setOpenModalAdd}
                title={"Добавить язык"}
                textLeftButton={"Отменить"}
                textRightButton={"Подтвердить"}
                field
                buttonRight={addLang}
            />
            <ModalGeneral
                showModal={openModalDelete}
                setShowModal={setOpenModalDelete}
                image={questionMark}
                buttonRight={deleteLang}
                title={"Удалить язык ?"}
                textLeftButton={"Отменить"}
                textRightButton={"Удалить"}
                description={description}
            />
        </section>
    )
}
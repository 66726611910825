import s from "./HeaderSection.module.scss";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import React from "react";

type HeaderSection = {
    title: string;
    buttonText?: string;
    icon?: any;
    onClick?: any;
    description?: string;
    disabled?: boolean;
}

export const HeaderSection = ({ title, buttonText, icon, onClick, description, disabled }: HeaderSection) => {
    return (
        <div className={s.header_section}>
            <section className={s.header}>
                <h1 className={s.title}>{title}</h1>
                {buttonText && <PrimaryButton
                    text={buttonText}
                    type={"button"}
                    className={s.button}
                    icon={icon}
                    onClick={onClick && onClick}
                    disabled={disabled}
                />}
            </section>

            {description && <section className={s.description}>{description}</section>}
        </div>
    )
}
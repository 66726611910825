import React from 'react';
import s from './Dashboard.module.scss';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import {useGetPermissionsQuery, useGetUsersQuery} from "../../redux/apis/userApi";
import {useNavigate} from "react-router-dom";
import {getFormattedDate} from "utils/getFormattedDate";
import {useGetSectionsQuery} from "../../redux/apis/sectionApi";
import {HeaderSection} from "components/HeaderSection/HeaderSection";

export const Dashboard = () => {
    const { data: users } = useGetUsersQuery({});
    const {currentData: sections} = useGetSectionsQuery({});
    const navigate = useNavigate();
    const {data: permissions} = useGetPermissionsQuery({});
    const allowPagesEdit = permissions?.permissions?.pages?.edit;
    const userDataString = localStorage.getItem('userData');
    const userData = userDataString ? JSON.parse(userDataString) : null;

    console.log(userData);

    const navigateToEdit = (section: any) => {
        navigate("/pages", { state: { section: section} });
    }

    return (
        <div className={s.dashboard}>
            <HeaderSection
                title={"Главная страница"}
                buttonText={"Настроить"}
                icon={<Brush />}
                disabled
            />

            <section className={s.dashboard_welcome}>
                <p>{getFormattedDate()}</p>
                <h2>Здравствуйте {userData?.info?.name}</h2>
            </section>

            <section className={s.dashboard_main}>
                <div className={s.container}>
                    <div className={s.header}>
                        <p className={s.title}>Пользователи <span>({ users?.length } user)</span></p>
                        <div className={s.container_button} onClick={() => navigate("/users")}>Все</div>
                    </div>

                    <div className={s.container_overflow}>
                        {users?.map((user: any) => (
                            <div className={s.container_wrap}>
                                <div className={s.container_detail}>
                                    {/*<div className={s.image}></div>*/}
                                    <p className={s.name}>{user?.info?.name}</p>
                                    <p className={s.email}>{user?.email}</p>
                                </div>

                                <div className={s.container_role}>Main admin</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={s.container}>
                    <div className={s.header}>
                        <p className={s.title}>Страницы & Модули</p>
                        <div className={s.container_button} onClick={() => navigate("/pages")}>Все</div>
                    </div>
                    <div className={s.page}>Страница 1</div>
                    <div className={s.container_overflow}>
                        {sections?.data?.map((section: any) => (
                            <div className={`${s.container_wrap} ${s.container_modules} ${!allowPagesEdit && s.disabled}`}>
                                <p className={`${s.name} ${s.section}`}>{section?.name}</p>
                                <div className={s.edit} onClick={() => allowPagesEdit && navigateToEdit(section)}>
                                    Редактировать
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}
import React, {useState} from 'react';
import s from './Auth.module.scss';
import background from 'assets/images/background.webp';
import logo from 'assets/icons/logo.svg';

import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {useAuthMutation} from "../../redux/apis/userApi";
import {useCrypto} from "utils/crypto";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setIsLoading} from "../../redux/slices/mainSlice";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {Input} from "components/Input/Input";

export const Auth = () => {
    const [auth, {data, isLoading, isError}] = useAuthMutation();
    let response = "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordLength, setPasswordLength] = useState(0);

    const initialValues = { email: '', password: ''}
    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required('Обязательное поле')
            .min(14, 'Пароль должен содержать не менее 14 символов')
            .matches(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
            .matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру'),

        email: yup.string().required('Обязательное поле').email('Не корректный email')
    });

    function LogIn(values: any){
        const email = values.email;
        const password = useCrypto(values.password);
        auth({email: email, password: password});
    }

    if (isError) console.log(isError);
    if (isLoading) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));
    if (data) {
        if(data.message === 8) response = "Не правильный email или пароль";
        if(data.message === 10){
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("userData", JSON.stringify(data.data.userData));
            navigate("/");
        }
    }

    return (
        <div className={s.container}>
            <div className={s.login}>
                <img
                    src={logo}
                    alt={s.logo}
                    className={s.logo}
                />

                <div className={s.wrap_form}>
                    <h1>Войти</h1>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values)=> LogIn(values)}
                    >
                        {({
                              errors,
                              touched,
                              isValid,
                              dirty,
                              setFieldValue
                        }) => (
                            <Form className={s.auth_form}>

                                <Input
                                    label={"Электронная почта"}
                                    errors={errors.email}
                                    touched={touched.email}
                                    name={"email"}
                                    placeholder={"Введите свою электронную почту"}
                                    onClick={() => setFieldValue('email', '')}
                                />

                                <Input
                                    label={"Пароль"}
                                    errors={errors.password}
                                    touched={touched.password}
                                    name={"password"}
                                    placeholder={"Введите свой пароль"}
                                    password
                                />

                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                    text={"Войти"}
                                />

                                <span className={s.error_text}>{response}</span>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <div className={s.image}>
                <img src={background} alt={s.loginImage} />
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {useGetModuleForCRMQuery, useModuleUploadMutation, useUpdatePostMutation} from "../../redux/apis/modulesApi";
import {Preloader} from "components/Preloader/Preloader";
import {getSequenceTypes} from "utils/getSequenceTypes";
import {PreparationModuleData} from "utils/PreparationModuleData";
import {Toast} from "utils/toast";
import s from './Module.module.scss';
import {HeaderSection} from "../../components/HeaderSection/HeaderSection";
import {ModuleBlock} from "./components/ModuleBlock/ModuleBlock";

type ModuleProps = {
    id: number;
    title: string;
}

export const Module = ({ id, title }: ModuleProps) => {
    const [viewMode, setViewMode] = useState(true)
    const [textModal, setTextModal] = useState({state: false, values: false, langs: false, type: false, post_id: false, html: false});
    const [mediaModal, setMediaModal] = useState({state: false, values: false, type: false, post_id: false});
    const [newModuleData, setNewModuleData] = useState<any>(false);
    const [files, setFiles] = useState([]);
    const [uploadedModule, setUploadedModule] = useState(false);
    const [currentModule, setCurrentModule] = useState(false);
    const [langState, setLangState] = useState(false);

    const langs = useSelector((store: any) => store.main.langs);

    const [uploadModule, {isLoading: isUploadModuleLoading}] = useModuleUploadMutation();
    const [updateMedia, {isLoading: isUploadMediaLoading}] = useUpdatePostMutation()
    const { data, isLoading, isFetching } = useGetModuleForCRMQuery(id);

    useEffect(()=> {
        goToModule(uploadedModule)
    },[data]);

    useEffect(()=>{
        setLangState(langs[0]?.id);
    },[langs]);

    if( isLoading || isFetching || isUploadModuleLoading || isUploadMediaLoading) return <Preloader />
    // console.log(data);
    function setSequence(){
        // @ts-ignore
        const sequence = getSequenceTypes(id, data.types, title, langs, data.post.id)
        console.log(sequence);
        setNewModuleData(sequence)
    }

    // FUNCTIONS

    function openModal({state, langs, values, type, post_id, html}: any){
        if(type === "text" || type === "link") setTextModal({state: state, langs: langs, values: values, type: type, post_id: post_id, html: html})
        if(type === "image" || type === "video") setMediaModal({state: state, values: values, type: type, post_id: post_id})
    }

    async function sendNewModule(){
        const newData = JSON.parse(JSON.stringify(newModuleData))
        const data = PreparationModuleData(newData, ['title', 'editId', 'type', 'sequence', 'id', 'isBlock', 'id_allowed'])
        console.log("data", newData);
        const response: any = await uploadModule(data)

        if(response.data?.message === 28){
            if(files.length){
                const uploadResult = await Promise.all(files.map(async (file: any) => {
                    const post = response.data.data.find((el: any) => el.alt === file?.editID);
                    if(!post) return
                    const post_id = post.id

                    const uploadResult = await Promise.all(Object.entries(file?.file)?.map(async (el: any) => {

                        const formData = new FormData()
                        formData.append('file', el?.[1].file)
                        formData.append('alt', "test")
                        formData.append("post_id", post_id)
                        formData.append("type", "image")
                        formData.append("with_file", "true")
                        formData.append("langkey", el[0])
                        formData.append("pseudo", file?.editID)
                        formData.append("default", el[1].default)

                        const response_media = await updateMedia(formData)
                        return response_media.data?.message
                    }))
                    if(uploadResult.includes(28)) return 28
                    else return false
                }))
                console.log("uploadResult", uploadResult);
                if(uploadResult.includes(28)){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    setViewMode(true)
                    setNewModuleData(false)
                    setFiles([])
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
                setNewModuleData(false)
                setNewModuleData(false)
                setFiles([])
                setUploadedModule(response.data?.post_id)
            }
            else{
                Toast.fire({icon: 'success', title: 'Успіх'});
                setViewMode(true)
                setNewModuleData(false)
                setFiles([])
                setUploadedModule(response.data?.post_id)
            }
        }
        else if (response?.error?.data?.message === 1000){
            Swal.fire({
                title: 'На жаль, у вас немає необхідних прав',
                confirmButtonText: 'Закрити',
                customClass: {
                    actions: 'modal',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            })
            return;
        }
    }

    function goToModule(id: any){
        const anchor: any = document.getElementsByClassName(`anchor-${id}`)
        window.scrollTo({
            top: anchor[0]?.offsetTop,
            left: 100,
            behavior: "smooth",
        });
        setUploadedModule(false)
    }

    // VIEWS

    const module_blocks_view = data?.value?.map((el: any, index: number) => {
        return <ModuleBlock
            key={el.id}
            post={el}
            types={data.types}
            setModal={openModal}
            setViewMode={setViewMode}
            setNewModuleData={setNewModuleData}
            parrent_id={data.post.id}
            index={index}
            langState={langState}
            setLangState={setLangState}
        />
    })

    const module_buttons_view = data?.value.map((el: any, index: number) => {
        let name = el.data?.find((el: any) => el.title === 'name')
        if(name) name = name.value?.[0]?.value
        else name = `${el.title} ${index + 1}`
        return(
            <div
                key={index}
                className={`${s.page_item} ${currentModule === el.id? s.active:''}`}
                onClick={()=> goToModule(el.id)}
            >
                <p>{el.marker? el.marker : name}</p>
            </div>
        )
    })

    return (
        <>
            {viewMode ?
                (
                    <div className={s.module}>
                        <HeaderSection title={title} />
                        <header className={s.header}>{module_buttons_view}</header>
                        <div className={s.content}>
                            {/*{module_blocks_view}*/}
                            {data?.value?.length? null : <div className="button" onClick={()=>{setViewMode(false); setSequence()}}>{`Додати блок`}</div>}
                        </div>
                        {/*{textModal.state? <TextModal close={setTextModal} values={textModal.values} langs={textModal.langs} type={textModal.type} post_id={textModal.post_id} html={textModal.html}/> : null}*/}
                        {/*{mediaModal.state? <MediaModal close={setMediaModal} values={mediaModal.values} type={mediaModal.type} post_id={mediaModal.post_id}/> : null}*/}
                    </div>
                )
                :
                (
                    <div className={s.module}>
                        <h1>{title}</h1>
                        <div className={s.content}>

                            {/*<AddModuleBlock post={newModuleData.values} title={newModuleData.title} setModal={openModal} changeValuesData={setNewModuleData} setFiles={setFiles} files={files}/>*/}

                            <div className={s.buttons}>
                                <div className="button" onClick={sendNewModule}>{`Зберегти`}</div>
                                <div className="button" onClick={()=>setViewMode(true)}>{`Закрити`}</div>
                            </div>
                        </div>
                        {/*{textModal.state? <TextModal close={setTextModal} values={textModal.values} langs={textModal.langs} type={textModal.type} post_id={textModal.post_id} html={textModal.html}/> : null}*/}
                        {/*{mediaModal.state? <MediaModal close={setMediaModal} values={mediaModal.values} type={mediaModal.type} post_id={mediaModal.post_id}/> : null}*/}
                    </div>
                )
            }
        </>
    )
}
import React from 'react';
import {useDispatch} from "react-redux";
import * as yup from 'yup';
import {sectionApi, useAddSectionMutation, useUpdateSectionMutation} from "../../../../redux/apis/sectionApi";
import { Form, Formik} from "formik";
import {pagesApi} from "../../../../redux/apis/pagesApi";
import s from './SectionModal.module.scss';
import {Back} from "components/Back/Back";
import {Input} from "components/Input/Input";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useStatusContext} from "components/StatusProvider";

type SectionModal = {
    close: React.Dispatch<React.SetStateAction<any>>;
    el: any;
    pageState: any
}

export const SectionModal = ({ close, el, pageState }: SectionModal) => {
    const dispatch = useDispatch();
    const initialValues = {id: el? el.id : '', name: el? el.name : '', content: el? el.content : '', marker: el.marker? el.marker : ''};
    const validationSchema = yup.object().shape({
        name: yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа'),
        marker: yup.string().required("Обязательное поле").min(2, 'Мимимум 2 символа')
    });

    const [updateSection] = useUpdateSectionMutation();
    const [addSection] = useAddSectionMutation();
    const { openStatus } = useStatusContext();

    return (
        <div className={s.pages_modal}>
            <Back close={close} text={"Назад к секциям"} />

            <div className={s.form}>
                <h2>{el ? "Редактировать секцию" : "Добавить секцию"}</h2>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(false)
                        if (el) {
                            const res: any = await updateSection(values);
                            if (res.error?.data.message === 1000) {
                                openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                                console.log(res);
                                close(false);
                            } else {
                                openStatus('success', 'Операция успешно выполнена!');
                            }

                        } else {
                            const res: any = await addSection({
                                values,
                                pageState: {...pageState, marker: values.marker}
                            });
                            if (res.error?.data.message === 1000) {
                                openStatus('warning', 'К сожалению, у вас нет необходимых прав');
                                close(false);
                            } else if (res.error?.data.message === 34) {
                                openStatus('error', `Имя ${res.error.data.data.name} уже занято`);
                            } else {
                                openStatus('success', 'Операция успешно выполнена!');
                                // @ts-ignore
                                dispatch(pagesApi.util.resetApiState(['Pages']))
                                // @ts-ignore
                                dispatch(sectionApi.util.resetApiState(['Section']))
                                close(false);
                            }
                        }
                    }}
                >
                    {({errors, touched, setFieldValue}) => (
                        <Form className={s.pages_form}>
                            <Input
                                label={"Название секции"}
                                errors={errors.name}
                                touched={touched.name}
                                name={"name"}
                                onClick={() => setFieldValue('name', '')}
                            />

                            <Input
                                label={"Маркер"}
                                errors={errors.marker}
                                touched={touched.marker}
                                name={"marker"}
                                onClick={() => setFieldValue('marker', '')}
                            />

                            <div className={s.wrap_buttons}>
                                <PrimaryButton
                                    text={"Подтвердить"}
                                    type={"submit"}
                                    icon={<Check />}
                                />
                                <SecondaryButton
                                    text={"Отменить"}
                                    type={"button"}
                                    white
                                    onClick={() => close(false)}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}